<template>
    <footer class="bg-light text-center text-lg-start">
      <div class="container p-4">
        <div class="row">
          <!-- About Section -->
          <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
            <h5 class="text-uppercase">About NewsApp</h5>
            <p>
              NewsApp brings you the latest news from around the world, covering a wide range of topics from politics to entertainment.
            </p>
          </div>
  
          <!-- Links Section -->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase">Quick Links</h5>
            <ul class="list-unstyled mb-0">
              <li><a href="#" class="text-dark">Home</a></li>
              <li><a href="#" class="text-dark">About Us</a></li>
              <li><a href="#" class="text-dark">Contact</a></li>
            </ul>
          </div>
  
          <!-- Social Media Section -->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase">Follow Us</h5>
            <ul class="list-unstyled mb-0">
              <li><a href="#" class="text-dark">Facebook</a></li>
              <li><a href="#" class="text-dark">Twitter</a></li>
              <li><a href="#" class="text-dark">Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- Copyright Section -->
      <div class="text-center p-3 bg-dark text-white">
        © 2024 Copyright: 
        <a class="text-white" href="https://newsapp.com/">NewsApp.com</a>
      </div>
    </footer>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  footer {
    position: relative;
    bottom: 0;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  
  </style>
  