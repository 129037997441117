<template>
  <div id="app">
    <Header/>
    <div class="container my-4">
      <h2 class="mb-4">Welcome to NewsApp, The Best reliable source for latest news online today</h2>

      <!-- Search Bar -->
      <div class="row mb-3">
        <div class="col-md-8">
          <input
            v-model="searchQuery"
            @input="fetchNews"
            type="text"
            class="form-control"
            placeholder="Search news..."
          />
        </div>

        <!-- Category Filter -->
        <div class="col-md-4">
          <select v-model="category" @change="fetchNews" class="form-select">
            <option value="">All Categories</option>
            <option value="business">Business</option>
            <option value="entertainment">Entertainment</option>
            <option value="general">General</option>
            <option value="health">Health</option>
            <option value="science">Science</option>
            <option value="sports">Sports</option>
            <option value="technology">Technology</option>
          </select>
        </div>
      </div>

      <!-- News Articles -->
      <div v-if="loading" class="text-center">
        <p>Loading...</p>
      </div>

      <div v-else class="row">
        <div class="col-md-4" v-for="article in paginatedArticles" :key="article.url">
          <div class="card mb-4">
            <img :src="article.urlToImage" class="card-img-top" alt="No Image" v-if="article.urlToImage" />
            <div class="card-body">
              <h5 class="card-title">{{ article.title }}</h5>
              <p class="card-text">{{ article.description }}</p>
              <a :href="article.url" class="btn btn-primary" target="_blank">Read More</a>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination Controls -->
      <nav aria-label="Page navigation" class="mt-4">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }">
            <button class="page-link" @click="changePage(page)">{{ page }}</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  <Footer/>
  </div>
</template>

<script>
import axios from "axios";
import Header from './components/HeaderPage.vue'
import Footer from './components/FooterPage.vue'
export default {
  name: 'App',
  components: {
    Header,Footer,
  },
  data() {
    return {
      articles: [],
      loading: true,
      currentPage: 1,
      pageSize: 6, // Number of articles per page
      totalPages: 1,
      apiKey: "062ea9a62ce04813a5f1d9d6a00552c6",
      searchQuery: "",
      category: "",
    };
  },
  computed: {
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.articles.slice(start, end);
    },
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    async fetchNews() {
      this.loading = true;
      try {
        const response = await axios.get(" https://newsapi.org/v2/top-headlines", {
          params: {
            apiKey: this.apiKey,
            q: this.searchQuery || null, // Search query if available
            category: this.category || null, // Category filter
            country: "us", // Fetch news from the US
          },
        });
        this.articles = response.data.articles;
        this.totalPages = Math.ceil(this.articles.length / this.pageSize);
        this.currentPage = 1; // Reset to first page after fetching
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
/* Optional styling */
.card-img-top {
  height: 200px;
  object-fit: cover;
}
.card{
  box-shadow: 0px 4px 8px 0px #0000003e;
  margin: 10px;
}
</style>
